<template>
  <v-container class="container-card">
    <v-card>
      <Index />
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import Index from "@/components/comissao/metas/individual/Index";
export default {
  components: {
    Index
  },

  methods: {
    ...mapActions({
      fetchView: "sistema/fetchView"
    })
  },

  mounted() {
    this.fetchView("Meta Individual");
  }
};
</script>
